.logo {
  color: #fff;
  vertical-align: center;
  max-width: 300px;

  .Logo_description {
    font-size: 12rem;
    font-size: 0.8rem;
    padding: 0;
    width: 100%;
    display: inline-block;
    overflow: hidden;
    margin-left: 30px;
    margin-top: -8px;
  }
}

.divi,
button {
  float: right;
}

header .ant-btn-primary {
  margin-left: 12px;
  border: solid 1px #fff;
  background-color: transparent;
}

/**
 * style logout btn
 */
.menuuser .ant-btn-primary {
  width: auto;
  border: 1px solid #d9d9d9;

}

.menuuser .ant-btn-primary:hover icon {
  width: auto;
  background: none;
  border-color: #003a6c;
}

@media (max-width: 576px) {
  .logout-btn {
    display: none !important;
  }
}


header .ant-menu-horizontal > .ant-menu-item:hover, .ant-menu-horizontal > .ant-menu-submenu:hover, .ant-menu-horizontal > .ant-menu-item-active, .ant-menu-horizontal > .ant-menu-submenu-active, .ant-menu-horizontal > .ant-menu-item-open, .ant-menu-horizontal > .ant-menu-submenu-open, .ant-menu-horizontal > .ant-menu-item-selected, .ant-menu-horizontal > .ant-menu-submenu-selected,
header .ant-menu-horizontal > .ant-menu-item:hover .anticon,.ant-menu-item, .ant-menu-submenu-title:hover{
  color: #fff !important;
  border-bottom: none !important;
}
.ant-menu.ant-menu-dark .ant-menu-item-selected, .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  color: #f29663 !important;
  
  background: none !important;
}

.ant-menu-vertical {
  background-color: rgb(49, 50, 55) !important;
  .ant-menu-item .dtb-menu-icon{
    font-size: 16px;
  }
  .ant-menu-item a { 
    color: rgb(238, 240, 244) !important;
    font-weight: 300 !important;
    font-size: 17px;
    font-variant:inherit;
    letter-spacing: 1px; 
    text-decoration: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    -webkit-transition: color 0.3s;
    transition: color 0.3s;
  } 
}