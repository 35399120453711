.wrapper{
  margin: 10px;
}

.login-form-fields {
  background: #fff;
  padding: 20px;
}
.dtb-new-password{
  width: '100%';
  background: #fff;
  padding:30px;
}

@media (min-width: 768px) {
  .dtb-new-password{
    width: '500px';
    align-items:'center';
    margin:0
  }
}
