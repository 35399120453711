.about-wrapper{
  background: none !important;
  h1{
    text-align: center;
  }
  width: 100%;
  height: 100%;// calc(100% - 400px);
  overflow-y: scroll !important;
  background: #fff;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}
.avoid-break {
  break-inside: avoid-column;
}
.dtb-long-text {

  column-count: 1;
  column-gap: 40px;
  column-rule-style: solid;
  column-rule-width: 1px;
  column-rule-color: #ccc;
  
}
.dtb-imprint-text-layout {
  column-gap: 60px;
  
}

.avoid-break {
  break-inside: avoid-column;
}

@media only screen and (min-width: 576px) {
  .dtb-long-text {
    column-count: 1;
    column-gap: 40px;
    column-rule-style: solid;
    column-rule-width: 1px;
    column-rule-color: #ccc;
  }
  .dtb-imprint-text-layout {
    column-gap: 60px;
  }
}

@media only screen and (min-width: 576px) {
  .dtb-long-text {
    column-count: 1;
  }
}

@media only screen and (min-width: 768px) {
  .dtb-long-text {
    column-count: 1;
  }
}

@media only screen and (min-width: 992px) {
  .dtb-long-text {
    column-count: 2;
  }
}

@media only screen and (min-width: 1200px) {
  .dtb-long-text {
    column-count: 2;
  }
}

@media only screen and (min-width: 1400px) {
  .dtb-long-text {
    column-count: 3;
  }
}